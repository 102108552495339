<template >
      <div style="    margin: 30px 20px;">
        <button style=" display: none" id="btn" >点我唤起</button>
    </div>
</template>

<script>

import { tuiguang }from '../api/authority'
// import 'highlight.js/styles/monokai-sublime.css';
export default {
      name: "tuiguang",
  
    data() {
        return{
        linkId:undefined,
    channel:'',
        }
     
        
    },

mounted() {

  this.channel=this.$route.query.channel
  // this.getlmg(this.channel)
  

    let linkId
   tuiguang({channel: this.channel}).then((response)=>{
           linkId=response.data.linkId
   const script = document.createElement("script");

    script.type = "text/javascript";

    script.src = "https://g.alicdn.com/jssdk/u-link/index.min.js";

    document.getElementsByTagName("head")[0].appendChild(script);
 console.log(window,"window")
   //等待挂载完成
   script.onload = function () {
    window.ULink({
         id:linkId,    //后台生成的裂变活动LinkID
        //   data: {},         //data就是前端传给app的一些自定义参数，自定义参数可能会从url上获取而来，这个随意
          selector: "#btn",     //页面下载按钮的id，点击会触发跳转（页面按钮好像只能用button标签，不能用div）
          auto:true,
          timeout:200,
        lazy:false,
           useOpenInBrowerTips: "default",
      proxyOpenDownload: function (defaultAction, LinkInstance){
        if (LinkInstance.solution.type === "scheme"){
          // qq或者微信环境特殊处理下
          if (ULink.isWechat || ULink.isQQ) {
            // 在qq或者微信环境执行内置逻辑，具体内置逻辑为:当设置了useOpenInBrowerTips字段时，qq&&微信&&scheme时，启用蒙层提示去浏览器打开
            defaultAction();
          }else{
            window.location.href = LinkInstance.solution.downloadUrl;
          }
        }else if(LinkInstance.solution.type === "universalLink"){
          // universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟已于6月2日上线universalLink生成及重定向功能。
        }
      },
    
   })
 
   }
     
          })
  
},
onload(){
   
},
 methods:{

    getlmg(){
        tuiguang({channel:this.channel}).then((response)=>{
           this.linkId=response.data.linkId
          })               
        }
    }
}
</script>
<style>
*{
   -webkit-text-size-adjust: none;
}
html,body{ 
  /* -webkit-text-size-adjust: 100% !important; */
  -webkit-text-size-adjust: none;
      margin: 0;
    padding: 0;
}  
   .conter{
    margin: 40px 30px;
        }
      /* .title  {
          widows: 171px;
          height: 28px;
          font-size:20px ;
          color: #0A1B33;
          font-weight: 800;
              display: flex;
    justify-content: space-between;
      }
      .title_two{
               font-size:12px ;
          color:#999999 ;

      }
      p{
        max-height: 9999px;
          text-align: left;
          color: #0A1B33 ;
          font-size: 15px;
            padding-bottom: 15px;
            
      }
      font{
          font-size: 15px;
      }
      h1{
          font-size:14px ;
          color: #0A1B33;
          font-weight: 600;
         padding-bottom: 10px;
      }
      h2{
          font-size:13px ;
          color: #0A1B33;
          font-weight: 600;
         padding-bottom: 10px;
      }
        h3{
          font-size:12px ;
          color: #0A1B33;
          font-weight: 550;
          padding-bottom: 10px;
      } */

</style>